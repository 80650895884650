<template>
  <input
    @keyup="$emit('update:value', $event.target.value)"
    type="text"
    :value="value"
    class="`
      flex-grow px-3 py-1 font-bold rounded-lg focus:outline-none bg-backfill
      min-w-0 text-content
    `"
    :placeholder="placeholder"
  >
</template>

<script>
export default {
  name: 'SearchBar',
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search...',
    },
  },
};
</script>

<style>

</style>
