<template>
  <button
    @click="$emit('click')"
    :disabled="disabled"
    :class="[
      disabled ?
        'bg-gray-600 text-gray-300 cursor-not-allowed' :
        bgColor,
        {'animate-pulse border-2 rounded-lg border-yellow-300':pulse}
    ]"
    class="`
      flex flex-shrink-0 flex-grow-0 items-center
      px-5 py-3 font-bold text-white rounded-lg
      focus:outline-none
      justify-center
      button-large
    `"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonLarge',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: true,
      validate: (val) => ['green', 'red', 'primary', 'secondary', 'tertiary'].inlcudes(val),
    },
    pulse: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  computed: {
    bgColor() {
      if (this.color === 'primary') return 'bg-primary-light';
      if (['green', 'red'].filter((c) => c === this.color).length > 0) {
        return `bg-${this.color}-500`;
      }
      return `bg-${this.color}`;
    },
  },
};
</script>
