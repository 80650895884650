<template>
    <interior-page-content-container
      nav-selection="invite"
      @back-click="backNav"
      :show-bottom-nav="true"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="false"
      :modal-show="showModal"
      :notificationMessage.sync="notificationMessage"
      :notificationType="notificationType"
      :notificationTime="notificationTime"
      :showRightControls="false"
    >
      <template #notification>
        {{notificationMessage}}
      </template>

      <template #modal>
        <invite-user-modal
          @confirm="addUsers"
          @cancel="showModal = false"
        />
      </template>

      <template #title>
        Invite
      </template>

      <template #content>

        <div class="`
          relative
          flex flex-col items-stretch self-center h-full pt-10
          w-5/6 phone:w-3/4 md:w-2/3 lg:w-3/5 max-w-md
        `">

          <div class="absolute top-0 left-0 right-0 flex flex-row justify-center mx-auto">
            <div
              class="`
                flex-grow-0 flex flex-col flex-shrink-0 pt-2 bg-background
              `"
            >
              <search-bar
                :value.sync="search"
              />
            </div>
          </div>

          <div class="mt-5">
            <invited-user-component
              v-for="user in filteredUsers" :key="user"
              :email="user"
              class="mb-2"
            />
          </div>

          <div
            v-if="users.length === 0 && pastInvitedUsers.length === 0"
            class="px-10 text-content pt-14"
          >
            <p>You currently have not invited any users.</p>
            <p>Click the button below to invite your first!</p>
          </div>

          <p
            v-if="isUserSearchEmpty"
            class="px-10 text-content pt-14"
          >
            No users match your query
          </p>

          <div class="flex flex-col justify-end flex-grow flex-shrink-0 pt-4 pb-10">
            <button-large
              @click="showModal = true"
              color="primary"
              class="self-center w-32"
            >
              Invite User
            </button-large>
          </div>

        </div>
      </template>

      <template #bottom-nav-center>
        <circle-plus
          @click="showModal = true"
          class="cursor-pointer invite-user"
          :size='37'
        />
      </template>

    </interior-page-content-container>
</template>

<script>

import { USER_INVITE, USER_WALKTHROUGH_DISPATCH } from '@/store/actions';
import { USER_INVITED_EMAILS, USER_WALKTHROUGH_STATE } from '@/store/getters';

import CirclePlus from 'vue-material-design-icons/PlusCircle.vue';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import SearchBar from '@/components/shared/inputs/SearchBar.vue';
import InvitedUserComponent from '@/components/invite/InvitedUserComponent.vue';
import InviteUserModal from '@/components/invite/InviteUserModal.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'Invite',
  components: {
    InteriorPageContentContainer,
    SearchBar,
    InvitedUserComponent,
    InviteUserModal,
    CirclePlus,
    ButtonLarge,
  },
  data() {
    return {
      search: '',
      showModal: false,
      notificationType: 'success',
      notificationMessage: '',
      notificationTime: 5000,
      users: [],
    };
  },
  computed: {
    pastInvitedUsers() {
      return this.$store.getters[USER_INVITED_EMAILS];
    },
    isUserSearchEmpty() {
      return (this.users.length !== 0 || this.pastInvitedUsers.length !== 0)
        && this.filteredUsers.length === 0;
    },
    filteredUsers() {
      const users = [
        ...this.pastInvitedUsers,
        ...this.users,
      ];
      return users.filter((user) => user.includes(this.search));
    },
    walkthroughStage() {
      return this.$store.getters[USER_WALKTHROUGH_STATE];
    },
  },
  watch: {
    walkthroughStage() {
      this.onWalkthrough();
    },
    notificationMessage(newVal) {
      if (newVal.length === 0 && this.$store.getters[USER_WALKTHROUGH_STATE] === 17) {
        this.$router.push({ name: 'Dashboard' });
      }
    },
  },
  mounted() {
    this.onWalkthrough();
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters[USER_WALKTHROUGH_STATE] < 100) {
      if (to.name === 'Dashboard') {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
      } else {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
      }
    }
    next();
  },
  methods: {
    onWalkthrough() {
      if (this.$store.getters[USER_WALKTHROUGH_STATE] === 17) {
        this.notificationTime = 5000;
        this.notificationType = 'success';
        this.notificationMessage = 'This is the User Invitation Center!';
      }
    },
    backNav() {
      this.$router.push('Dashboard');
    },
    async addUsers(emails) {
      const promises = [];
      const errors = [];
      const users = [...this.users];
      emails.forEach((email) => {
        if (users.includes(email)) {
          errors.push(email);
        } else {
          promises.push(
            this.$store.dispatch(USER_INVITE, email)
              .catch(() => {
                errors.push(email);
              }),
          );
        }
      });
      this.showModal = false;
      this.loading = true;
      Promise.all(promises)
        .finally(() => {
          if (errors.length === 0) {
            this.notificationMessage = `Invitation${emails.length > 1 ? 's are' : ' is'} being sent!`;
            this.notificationType = 'success';
            this.notificationTime = 5000;
          } else {
            let emailErrors = errors.reduce((pastVal, curVal) => `${pastVal} ${curVal}`, '').trim();
            emailErrors = emailErrors.split(' ').join(', ');
            this.notificationMessage = `The following invitation${errors.length > 1 ? 's' : ''} were not sent: ${emailErrors}`;
            this.notificationTime = 7000;
            this.notificationType = 'alert';
          }
          this.loading = false;
        });
    },
  },
};
</script>
