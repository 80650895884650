<template>
  <div
    @click="$emit('click')"
    class="`
      flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
      rounded-lg w-full bg-backfill h-10 items-center
      invited-user-component
    `"
  >
    <div class="flex justify-between w-full px-4 pt-1">
      <p class="mb-1 font-bold text-left text-primary">{{email}}</p>
      <p class="font-bold text-content">Invited!</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InvitedUserComponent',
  props: {
    email: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>

</style>
